import type { AppProps, AppContext, AppInitialProps } from 'next/app';
import App from 'next/app';
import '../styles/globals.scss'
import "@h5/public/Swiper/min.scss";
import { useRouter } from 'next/router';
import WebLayout from '@/components/WebLayout';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  // web 路径但不是 init 页面，使用 WebLayout 包裹
  if (router.pathname.startsWith('/web') && !router.pathname.includes('/init')) {
    return (
      <WebLayout>
        <Component {...pageProps} />
      </WebLayout>
    );
  }

  // init 页面和其他页面直接渲染
  return <Component {...pageProps} />;
}

MyApp.getInitialProps = async (appContext: AppContext): Promise<AppInitialProps> => {
  // 从App获取基本的pageProps（这会调用各个页面的getInitialProps，如果有的话）
  const appProps = await App.getInitialProps(appContext);

  const { Component, ctx } = appContext;
  let pageProps = appProps.pageProps || {};

  // 安全检查，只有当组件存在且有 getInitialProps 方法时才调用
  if (Component && (Component as any).getInitialProps) {
    pageProps = {
      ...pageProps,
      ...(await (Component as any).getInitialProps(ctx))
    };
  }

  return { ...appProps, pageProps };
};

export default MyApp
